import { Theme } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./metadata.module.scss";

const NMAAHC_OBJECT_REGEX = /^(A|SC|)\d{4}.\d+/m;

const Metadata = ({
  copyright,
  creditLine,
  currentItem,
  displayTitle,
  linkField,
  objectName,
  objectNumber,
  objectTitle,
  subjectMedium,
  theme,
  type,
  totalItems,
  noLine,
}) => {
  // Check to see if there is any metadata to render, returning an empty component if not
  const hasData = [
    copyright,
    creditLine,
    displayTitle,
    objectName,
    objectNumber,
    objectTitle,
  ].some(Boolean);
  if (!hasData) return null;

  const title = displayTitle || objectTitle || objectName;
  const hasObjectTitleLink = NMAAHC_OBJECT_REGEX.test(objectNumber);
  const metaClass = classNames("row", {
    "center-xs": type === "modal",
    "start-xs": type === "component" || type === "dissection",
    [styles.container]: type === "component" || type === "dissection",
    [styles.dissection]: type === "dissection",
    [styles.light]: theme === Theme.Light,
    [styles.dark]: theme === Theme.Dark,
    [styles.noLine]: noLine,
  });

  const dataClass = classNames({
    [styles.metadatum]: true,
    [styles.modal]: type === "modal",
    [styles.component]: type === "component" || type === "dissection",
  });

  const listClass = classNames({
    "col-sm-11": currentItem && totalItems,
    "col-xs-12": !currentItem || !totalItems,
    [styles.list]: type === "component" || type === "dissection",
  });

  return (
    <div className={metaClass} data-testid="metadata">
      <div className="col-xs-10 col-sm-8">
        <div className="row">
          {currentItem && totalItems && (
            <div className="col-xs-12 col-sm-1">
              <p className={dataClass}>
                {currentItem}/{totalItems}
              </p>
            </div>
          )}
          <div className={listClass}>
            {title && (
              <p className={dataClass} data-testid="title">
                {title}
              </p>
            )}
            {subjectMedium && (
              <p className={dataClass} data-testid={"medium"}>
                {subjectMedium}
              </p>
            )}
            {creditLine && (
              <p className={dataClass} data-testid="credit line">
                {(() => {
                  if (hasObjectTitleLink) {
                    return (
                      <a
                        data-testid="object number"
                        href={`https://nmaahc.si.edu/object/nmaahc_${objectNumber}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {creditLine}
                        <i className="icon-external-link" />
                      </a>
                    );
                  } else if (linkField) {
                    return (
                      <a
                        data-testid="link"
                        href={linkField}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {creditLine}
                        <i className="icon-external-link" />
                      </a>
                    );
                  } else {
                    return creditLine;
                  }
                })()}
              </p>
            )}
            {copyright && (
              <p className={dataClass}>
                <em data-testid="copyright">{copyright}</em>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Metadata.propTypes = {
  copyright: PropTypes.string,
  creditLine: PropTypes.string,
  currentItem: PropTypes.number,
  displayTitle: PropTypes.string,
  linkField: PropTypes.string,
  objectName: PropTypes.string,
  objectNumber: PropTypes.string,
  objectTitle: PropTypes.string,
  subjectMedium: PropTypes.string,
  ...Theme.PropType,
  noLine: PropTypes.bool,
  totalItems: PropTypes.number,
  type: PropTypes.oneOf(["component", "modal", "voyager", "dissection"]),
};

Metadata.defaultProps = {
  theme: Theme.Light,
  type: "modal",
  noLine: false,
};

const PropType = {
  metadata: PropTypes.shape({
    copyright: PropTypes.string,
    displayTitle: PropTypes.string,
    linkField: PropTypes.string,
    objectName: PropTypes.string,
    objectNumber: PropTypes.string,
    objectTitle: PropTypes.string,
    subjectMedium: PropTypes.string,
  }),
};

export { Metadata as default, NMAAHC_OBJECT_REGEX, PropType };
